import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly3D2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/class-assembly/3D/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/class-assembly/3D/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/class-assembly/3D/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/class-assembly/3D/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/class-assembly/3D/5.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },

        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },




        // {
        //   src: 'https://assets.maccarianagency.com/backgrounds/img21.webp',
        //   source: 'https://assets.maccarianagency.com/backgrounds/img21.webp',
        //   rows: 1,
        //   cols: 1,
        // },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Diwali
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 3 D	          Date: 04 November 2024 – 06 November 2024

                                </Typography>
                                {/* <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “Every child is a different kind of flower and all together make this world a beautiful garden.”

                                    <br />
                                </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    “Diwali is a festival that celebrates the victory of light over darkness, good over evil and knowledge over
                                    ignorance.
                                    <br></br>
                                    The students of Class 3D presented their assembly on the topic, ‘Diwali’ from Monday, 04 November to
                                    Wednesday, 06 November 2024. The assembly began with an inspiring thought for the day followed by a brief
                                    introduction on the significance of Diwali.
                                    <br></br>
                                    The highlight of the assembly was a skit titled &quot;Green Diwali,&quot; featuring characters like birds, plants, a group of
                                    friends and the Earth. A group of friends discussed the way they celebrated Diwali and a small talk on the effect
                                    of fireworks on our environment, followed by each character explaining the harm brought by the usage of
                                    firecrackers. The assembly concluded with a reflection on the importance of Green Diwali (Eco-Friendly Diwali)
                                    followed by a song where the students of Class 3D wished everyone a happy and prosperous Diwali.
                                    <br></br>
                                    <br></br>

                                </Typography>
                                <Box textAlign="center">
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        fontWeight="normal"
                                    >
                                        Diwali, a festival where we illuminate a lamp of happiness, blow a string of
                                        sorrows and blast a chain of prosperity
                                    </Typography>
                                </Box>

                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <ClassAssembly2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>
                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ClassAssembly3D2024;